<template>
  <div class="row">
    <div style="padding: 20px 12px 0">
      <span class="site-name"> {{ name }} </span>
      <button
        class="btn btn-primary"
        style="float: right"
        :class="{ 'display-none': tabs.inactive }"
        @click="() => addFreezerModal.show()"
      >
        {{ $t("sites.temperature.add_freezer") }}
      </button>
    </div>

    <ul class="nav nav-tabs m-t-sm" role="tablist" style="padding: 0 12px">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.active }"
          role="tab"
          :aria-selected="tabs.active"
          href="javascript:void(0);"
          @click="selectTab('active')"
        >
          Active ({{ freezers.active.length }})
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.inactive }"
          role="tab"
          :aria-selected="tabs.inactive"
          href="javascript:void(0);"
          @click="selectTab('inactive')"
        >
          Inactive ({{ freezers.inactive.length }})
        </button>
      </li>
    </ul>

    <div
      class="tab-pane fade"
      v-show="tabs.active"
      :class="{ 'show active': tabs.active }"
      role="tabpanel"
    >
      <active-freezers
        :list="freezers.active"
        @freezer="deactivate"
        :name="name"
      ></active-freezers>
    </div>

    <div
      class="tab-pane fade"
      v-show="tabs.inactive"
      :class="{ 'show active': tabs.inactive }"
      role="tabpanel"
    >
      <inactive-freezers
        :list="freezers.inactive"
        @freezer="activate"
        :name="name"
      ></inactive-freezers>
    </div>
  </div>

  <div
    class="modal fade"
    id="addFreezerModal"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xxl">
        <div class="modal-title">
          <h4 class="m-r-sm textAlign-center flex">
            <span class="widget-list-item-icon">
              <i
                class="material-icons-outlined"
                style="vertical-align: middle; padding: 10px"
              >
                kitchen</i
              >
            </span>
            {{ $t("sites.temperature.add_freezer").toUpperCase() }}
          </h4>
          <div class="form-group p-v-sm" style="width: 100%">
            <label class="form-label"> Freezer Name </label>
            <input
              type="text"
              v-model="freezerName"
              class="form-control"
              placeholder="Freezer Name"
            />
            <span class="text-danger" v-show="addFreezerError">
              Freezer name already exits
            </span>
          </div>
          <div class="flex-center p-v-sm">
            <button
              class="btn btn-secondary m-r-sm"
              @click="() => addFreezerModal.hide()"
            >
              {{ $t("global.close") }}
            </button>
            <button class="btn btn-primary" @click="addFreezer">
              <span
                v-show="addingFreezer"
                class="spinner-border spinner-border-sm m-r-xxs"
              ></span>
              <span>
                <i class="material-icons"> add </i> {{ $t("global.add") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import temperatureService from "../../../../../services/temperature.service";
import { DateTime } from "luxon";
import ActiveFreezers from "./freezers/activefreezers/ActiveFreezers.vue";
import InactiveFreezers from "./freezers/inactivefreezers/InactiveFreezers.vue";

export default {
  name: "Freezer",
  props: {
    site_id: Number | String,
    name: String,
  },
  components: {
    ActiveFreezers,
    InactiveFreezers,
  },
  data() {
    return {
      loading: true,
      freezers: {
        active: [],
        inactive: [],
      },
      tabs: {
        active: true,
        inactive: false,
      },
      addFreezerModal: null,
      freezerName: "",
      addingFreezer: false,
      addFreezerError: false,
    };
  },
  mounted() {
    this.getFreezers();
    this.addFreezerModal = new bootstrap.Modal(
      document.getElementById("addFreezerModal")
    );
  },
  methods: {
    getFreezers() {
      this.loading = true;
      temperatureService
        .getFreezers(this.site_id)
        .then((response) => {
          response.data.data.forEach((freezer) => {
            freezer.datetime = DateTime.fromSeconds(freezer.datetime).toFormat(
              "dd LLL yyyy HH:mm:ss"
            );
            if (freezer.status === 1) {
              this.freezers.active.push(freezer);
            } else {
              this.freezers.inactive.push(freezer);
            }
          });
          console.log(this.freezers);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    addFreezer() {
      this.addingFreezer = true;
      this.addFreezerError = false;
      temperatureService
        .addNewFreezer(this.site_id, this.freezerName)
        .then((response) => {
          this.$success(this.$t("sites.temperature.add_freezer_success"));
          this.addingFreezer = false;
          this.freezers.active.unshift({
            name: response.data.freezer.name,
            datetime: DateTime.fromFormat(
              response.data.freezer.datetime.replace("Z", "").replace("T", " "),
              "yyyy-MM-dd HH:mm:ss"
            ).toFormat("dd LLL yyyy HH:mm:ss"),
          });
          this.addFreezerModal.hide();
        })
        .catch((error) => {
          console.error(error);
          this.addingFreezer = false;
          this.addFreezerError = true;
        });
    },
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
    activate(freezer) {
      console.log("activate", freezer);
      temperatureService
        .activateFreezer(this.site_id, freezer.id)
        .then((response) => {
          this.$success("Freezer activated successfully");
          this.freezers.active.unshift(freezer);
          this.freezers.inactive = this.freezers.inactive.filter(
            (f) => f.id !== freezer.id
          );
        })
        .catch((error) => {
          this.$error("Failed to activate Freezer", error);
        });
    },
    deactivate(freezer) {
      console.log("deactivate", freezer);
      temperatureService
        .deactivateFreezer(this.site_id, freezer.id)
        .then((response) => {
          this.$success("Freezer deactivated successfully");
          this.freezers.inactive.unshift(freezer);
          this.freezers.active = this.freezers.active.filter(
            (f) => f.id !== freezer.id
          );
        })
        .catch((error) => {
          this.$error("Failed to deactivate Freezer", error);
        });
    },
  }
}
</script>

<style scoped>
.nav-tabs {
  border-bottom: none;
}

.table-heading {
  margin-bottom: 0;
  margin-top: 40px;
  padding-top: 0px;
}

.widget-list-item-icon {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  text-align: center;
  margin-right: 10px;
  vertical-align: middle;
  float: left;
  color: #fd7e14;
  background: rgba(255, 149, 0, 0.25) !important;
}

.flex {
  align-items: center;
}

.display-none {
  display: none;
}

.site-name {
  font-size: 25px;
}

.m-r-sm.textAlign-center.flex {
  width: fit-content;
  margin: auto;
}

.text-danger {
  font-weight: 400;
}
</style>
