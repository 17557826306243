<template>
  <div class="p-v-sm">
    <button class="btn text-white float-right tx-uppercase tx-bold" @click="generateReport" style="background-color: #1D6F42;">Export Excel</button>

    <button class="btn btn-secondary m-b-sm" @click="$emit('hideFreezerTemperature')"> <i class="material-icons"> arrow_back_ios_new</i> Go back </button>

    <div class="accordion" id="filterAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
            {{$t('events.filters.title')}} ({{$t('events.filters.click_to_open')}})
          </button>
        </h2>
        <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
          <div class="accordion-body">
            <Form @submit="(values) => getFreezerTemperature(pagination.page, values)" :validation-schema="schema" ref="form">
              <div class="row"> 
                <div class=" form-group col-md-3 col-xs-6 col-xxs-12">
                  <label for="startDateFilter" class="form-label"> {{ $t('events.filters.start_date') }} </label>
                  <input type="date" name="startDate" class="form-control" id="startDateFilter" v-model="filters.start_date" placeholder="Select start date"/>
                </div>

                <div class="form-group col-md-3">
                  <label for="endDateFilter" class="form-label">{{ $t('events.filters.end_date') }}</label>
                  <input type="date" name="endDate" class="form-control" id="endDateFilter" v-model="filters.end_date" placeholder="Select end date"/>
                </div> 
              </div>

              <div class="row m-t-md">
                <div class="col-12" style="width:auto;">
                  <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;" @click.prevent="getFreezerTemp">
                    <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                    <span>{{ $t('events.filters.search') }}</span>
                  </button>
                </div>

                <div class="col-12" style="width:auto;">
                  <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click.prevent="clearFilter">
                    <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                    <span>{{ $t('global.clear_filter') }}</span>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <Pagination
      v-show="!loading"
      :config="pagination"
      @search ="getFreezerTemp"
    />

    <h6 class="table-heading"> FREEZER: {{freezer.name}} </h6>

    <table class="table table-striped m-b-lg" id="inactive-freezer-temp">
      <thead>
        <tr>
          <th>{{ $t('sites.site.temperature').toUpperCase() }}</th>
          <th> DATE/TIME RECORDED </th>
        </tr>
      </thead>

      <td colspan="2" v-if="loading" class="parent-div">
        <div class="spinner-border center-div" style="height: 4rem; width: 4rem;" role="status">
          <span class="visually-hidden">{{ $t('events.loading') }}...</span>
        </div>
      </td>

      <tbody v-else>
        <tr v-show="freezerTemp.length < 1">
          <td colspan="2" class="no-data-container"> No data to show </td>
        </tr>
        <tr v-for="freezer in freezerTemp" :key="freezer.id" style="vertical-align: middle;">
          <td> {{parseFloat(freezer.temperature).toFixed(2)}} </td>
          <td> {{freezer.datetime}} </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import temperatureService from '../../../../../../../services/temperature.service';
import Pagination from '../../../../../../../components/Pagination.vue';
import { DateTime } from 'luxon';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default { 
  name: 'InactiveFreezerTemperature',
  props: {
    freezer: Object
  },
  components: {
    Pagination
  },
  data() {
    return {
      loading: false,
      filters: {
        start_date: null,
        end_date: null
      },
      freezerTemp: [],
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0
      }
    }
  },
  mounted() {
    this.getFreezerTemperature();
  },
  methods: {
    getFreezerTemperature(page = 1) {
      this.loading = true;
      temperatureService.getFreezerTemperature(this.$route.params.id, this.freezer.id, this.filters.start_date, this.filters.end_date, page)
        .then(response => {
          this.freezerTemp = response.data.submissions;
          this.freezerTemp.forEach((temp) => {
            temp.datetime = DateTime.fromSeconds(temp.datetime).toFormat('dd LLL yyyy HH:mm:ss');
          });
          this.pagination = response.data.pagination;
        })
        .catch(error => {
          this.$error(this.$t('temperature.freezer_temp_error'), error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilter() {
      this.filters.start_date = null;
      this.filters.end_date = null;
    },
    async fetchAllFreezerData() {
      const allData = [];

      for (let page = 1; page <= this.pagination.lastPage; page++) {
        try {
          const response = await temperatureService.getFreezerTemperature(
            this.$route.params.id,
            this.freezer.id,
            this.filters.start_date,
            this.filters.end_date,
            page
          );

          const pageData = response.data.submissions;
          allData.push(...pageData);
        } catch (error) {
          console.error("Error Fetching Freezer Data:", error);
        }
      }
      return allData;
    },
    async generateReport() {
      console.log("Exporting Excel");
      const allData = await this.fetchAllFreezerData();

      if (allData.length === 0) {
        window.alert("No data to export");
      } else {
        const temperatureData = allData.map((entry) => ({
          "Freezer Temperature": parseFloat(entry.temperature).toFixed(2),
          "Date/Time": DateTime.fromSeconds(entry.datetime).toFormat('dd LLL yyyy HH:mm:ss')
        }));

        const book = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(temperatureData);
        XLSX.utils.book_append_sheet(book, ws, this.freezer.name + ' Freezer Report');
        const wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };
        const wbout = XLSX.write(book, wopts);
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), this.freezer.name + ' Freezer Report.xlsx');
      }
    }
  }
}

</script>