<template>
  <div>
    <div v-if= "!freezerSelected" class="p-v-sm">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th> {{$t('sites.name').toUpperCase()}} </th>
            <th> DATE/TIME CREATED </th>
            <th>  </th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="list.length < 1">
            <td colspan="3" class="no-data-container">{{$t('global.no_data')}}</td>
          </tr>
          <tr v-for="elem of list" :key="elem.index">
            <td class="cur_pointer" @click="showFreezerTemp(elem)"> {{elem.name}} </td>
            <td class="cur_pointer" @click="showFreezerTemp(elem)"> {{elem.datetime}} </td>
            <td> <button class="btn btn-sm btn-danger" @click="activate(elem)">Activate</button> </td>
          </tr>
        </tbody>
      </table>
    </div>

    <inactive-freezer-temperature v-if="freezerSelected" :freezer="selectedFreezer" @hideFreezerTemperature="() => {freezerSelected = !freezerSelected}"></inactive-freezer-temperature>

  </div>
</template>

<script>
import InactiveFreezerTemperature from './InactiveFreezerTemperature.vue';

export default {
  name: "InactiveFreezers",
  props: {
    list: Array
  },
  components: {
    InactiveFreezerTemperature
  },
  data() {
    return {
      selectedFreezer: {},
      freezerSelected: false
    }
  },
  methods: {
    showFreezerTemp(freezer) {
      this.selectedFreezer = freezer;
      this.freezerSelected = true;
    },
    activate(freezer){
      this.$emit('freezer', freezer)
    }
  }
}
</script>