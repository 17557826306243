<template>
  <div class="row">
    <div class="col-9" style="margin-left: 280px">
      <!-- <h1 v-if="sites.length === 1" class="table-heading"> {{sites[0].name}} </h1> -->

      <ul class="nav nav-pills nav-justified" id="temperature">
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0);"
            :class="{ active: tabs.fridge }"
            role="tab"
            :aria-selected="tabs.fridge"
            @click="selectTab('fridge')"
          >
            {{ $t("sites.temperature.fridges") }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0);"
            :class="{ active: tabs.freezer }"
            role="tab"
            :aria-selected="tabs.freezer"
            @click="selectTab('freezer')"
          >
            {{ $t("sites.temperature.freezer") }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0);"
            :class="{ active: tabs.beverages }"
            role="tab"
            :aria-selected="tabs.beverages"
            @click="selectTab('beverages')"
          >
            {{ $t("sites.temperature.food") }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0);"
            :class="{ active: tabs.calibration }"
            role="tab"
            :aria-selected="tabs.calibration"
            @click="selectTab('calibration')"
          >
            {{ $t("sites.temperature.calibration") }}
          </a>
        </li>
      </ul>

      <div
        class="tab-pane fade"
        v-show="tabs.fridge"
        :class="{ 'show active': tabs.fridge }"
        role="tabpanel"
      >
        <fridge :site_id="$route.params.id" :name="site.name" />
      </div>

      <div
        class="tab-pane fade"
        v-show="tabs.freezer"
        :class="{ 'show active': tabs.freezer }"
        role="tabpanel"
      >
        <freezer :site_id="$route.params.id" :name="site.name" />
      </div>

      <div
        class="tab-pane fade"
        v-show="tabs.beverages"
        :class="{ 'show active': tabs.beverages }"
        role="tabpanel"
      >
        <beverages :site_id="$route.params.id" :name="site.name" />
      </div>

      <div
        class="tab-pane fade"
        v-show="tabs.calibration"
        :class="{ 'show active': tabs.calibration }"
        role="tabpanel"
      >
        <Calibration :site_id="$route.params.id" :name="site.name" />
      </div>
    </div>
  </div>
</template>

<script>
import Fridge from "./fridge/Fridge.vue";
import Freezer from "./freezer/Freezer.vue";
import Beverages from "./food/Beverages.vue";
import Calibration from "./calibration/Calibration.vue";

export default {
  name: "Temperature",
  components: {
    Fridge,
    Freezer,
    Beverages,
    Calibration,
  },
  props: {
    site: Object,
  },
  data() {
    return {
      tabs: {
        fridge: true,
        freezer: false,
        beverages: false,
        calibration: false,
      },
    };
  },
  mounted() {},
  methods: {
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
  },
};
</script>

<style scoped></style>
